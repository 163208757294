<template>
  <page-setup>
    <v-container fluid>
      <v-row dense>
        <v-col>
          <div class="text-h5 text-color-main font-weight-black my-4">
            {{ title }}
          </div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col md="4">
          <experience-details-card :experience="experience"></experience-details-card>
        </v-col>
        <v-col md="8" class="px-4">
          <poll-details-card :experienceParts="experience.parts"></poll-details-card>
        </v-col>
      </v-row>
    </v-container>
  </page-setup>
</template>

<script>
import { RepositoryFactory } from "@/services/repositories/repositoryFactory";
const experienceRepository = RepositoryFactory.get("experience");

export default {
  components: {
    ExperienceDetailsCard: () => import("../cards/ExperienceDetailsCard.vue"),
    PollDetailsCard: () => import("../cards/PollDetailsCard.vue")
  },
  data: () => ({
    title: "Experience Details",
    experience: {}
  }),
  methods: {
    async getData() {
      let id = this.$route.params.id;
      await experienceRepository.show(id)
        .then(response => {
          this.experience = response.data.responseData;
        })
        .catch(() => {
          this.experience = {};
        })
    }
  },
  created() {
    this.getData();
  }
}
</script>
